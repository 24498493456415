/* Garnett */

@font-face {
  font-family: 'Garnett';
  font-weight: 400;
  font-display: block;
  font-style: 'normal';
  src: url('./garnett/Garnett-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Garnett';
  font-weight: 500;
  font-display: block;
  font-style: 'normal';
  src: url('./garnett/Garnett-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Garnett';
  font-weight: 600;
  font-display: block;
  font-style: 'normal';
  src: url('./garnett/Garnett-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Garnett';
  font-weight: 700;
  font-display: block;
  font-style: 'normal';
  src: url('./garnett/Garnett-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Garnett';
  font-weight: 800;
  font-display: block;
  font-style: 'normal';
  src: url('./garnett/Garnett-Black.ttf') format('truetype');
}

/* Faktum XCon */

@font-face {
  font-family: 'Faktum XCon';
  font-weight: 400;
  font-display: block;
  font-style: 'normal';
  src: url('./faktum/Faktum-XConRegular.otf') format('opentype');
}

@font-face {
  font-family: 'Faktum XCon';
  font-weight: 500;
  font-display: block;
  font-style: 'normal';
  src: url('./faktum/Faktum-XConMedium.otf') format('opentype');
}

@font-face {
  font-family: 'Faktum XCon';
  font-weight: 600;
  font-display: block;
  font-style: 'normal';
  src: url('./faktum/Faktum-XConSemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Faktum XCon';
  font-weight: 700;
  font-display: block;
  font-style: 'normal';
  src: url('./faktum/Faktum-XConBold.otf') format('opentype');
}

@font-face {
  font-family: 'Faktum XCon';
  font-weight: 800;
  font-display: block;
  font-style: 'normal';
  src: url('./faktum/Faktum-XConExtraBold.otf') format('opentype');
}

/* Faktum */

@font-face {
  font-family: 'Faktum';
  font-weight: 400;
  font-display: block;
  font-style: 'normal';
  src: url('./faktum/Faktum-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Faktum';
  font-weight: 500;
  font-display: block;
  font-style: 'normal';
  src: url('./faktum/Faktum-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Faktum';
  font-weight: 600;
  font-display: block;
  font-style: 'normal';
  src: url('./faktum/Faktum-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Faktum';
  font-weight: 700;
  font-display: block;
  font-style: 'normal';
  src: url('./faktum/Faktum-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Faktum';
  font-weight: 800;
  font-display: block;
  font-style: 'normal';
  src: url('./faktum/Faktum-ExtraBold.otf') format('opentype');
}

/* Capriola */

@font-face {
  font-family: 'Capriola';
  font-weight: 400;
  font-display: block;
  font-style: 'normal';
  src: url('./capriola/Capriola.ttf') format('truetype');
}

/* Playfair Display */

@font-face {
  font-family: 'Playfair Display';
  font-weight: 400;
  font-display: block;
  font-style: 'normal';
  src: url('./playfair-display/PlayfairDisplay.otf') format('opentype');
}

@font-face {
  font-family: 'Playfair Display';
  font-weight: 500;
  font-display: block;
  font-style: 'normal';
  src: url('./playfair-display/PlayfairDisplay-Bold.otf') format('opentype');
}

/* @font-face {
  font-family: 'Playfair Display';
  font-weight: 600;
  font-display: block;
  font-style: 'normal';
  src: url('./playfair-display/PlayfairDisplay-Black.otf') format('opentype');
} */

/* JetBrains Mono */

@font-face {
  font-family: 'JetBrains Mono';
  font-weight: 400;
  font-display: block;
  font-style: 'normal';
  src: url('./jetbrains-mono/JetBrainsMono.ttf') format('truetype');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-weight: 500;
  font-display: block;
  font-style: 'normal';
  src: url('./jetbrains-mono/JetBrainsMono-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-weight: 600;
  font-display: block;
  font-style: 'normal';
  src: url('./jetbrains-mono/JetBrainsMono-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'JetBrains Mono';
  font-weight: 700;
  font-display: block;
  font-style: 'normal';
  src: url('./jetbrains-mono/JetBrainsMono-ExtraBold.ttf') format('truetype');
}

/* Bookerly */

@font-face {
  font-family: 'Bookerly';
  font-weight: 400;
  font-display: block;
  font-style: 'normal';
  src: url('./bookerly/Bookerly.ttf') format('truetype');
}

@font-face {
  font-family: 'Bookerly';
  font-weight: 500;
  font-display: block;
  font-style: 'normal';
  src: url('./bookerly/Bookerly-Bold.ttf') format('truetype');
}

/* PublicaSans */

@font-face {
  font-family: 'PublicaSans';
  font-weight: 400;
  font-display: block;
  font-style: 'normal';
  src: url('./publica-sans/PublicaSans.otf') format('opentype');
}

@font-face {
  font-family: 'PublicaSans';
  font-weight: 500;
  font-display: block;
  font-style: 'normal';
  src: url('./publica-sans/PublicaSans-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'PublicaSans';
  font-weight: 600;
  font-display: block;
  font-style: 'normal';
  src: url('./publica-sans/PublicaSans-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'PublicaSans';
  font-weight: 700;
  font-display: block;
  font-style: 'normal';
  src: url('./publica-sans/PublicaSans-ExtraBold.otf') format('opentype');
}

@font-face {
  font-family: 'PublicaSans';
  font-weight: 800;
  font-display: block;
  font-style: 'normal';
  src: url('./publica-sans/PublicaSans-Black.otf') format('opentype');
}

/* PublicaSans */

@font-face {
  font-family: 'PublicaSans';
  font-weight: 400;
  font-display: block;
  font-style: 'normal';
  src: url('./publica-sans/PublicaSans.otf') format('opentype');
}

@font-face {
  font-family: 'PublicaSans';
  font-weight: 500;
  font-display: block;
  font-style: 'normal';
  src: url('./publica-sans/PublicaSans-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'PublicaSans';
  font-weight: 600;
  font-display: block;
  font-style: 'normal';
  src: url('./publica-sans/PublicaSans-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'PublicaSans';
  font-weight: 700;
  font-display: block;
  font-style: 'normal';
  src: url('./publica-sans/PublicaSans-ExtraBold.otf') format('opentype');
}

@font-face {
  font-family: 'PublicaSans';
  font-weight: 800;
  font-display: block;
  font-style: 'normal';
  src: url('./publica-sans/PublicaSans-Black.otf') format('opentype');
}

/* PolySans */

@font-face {
  font-family: 'PolySans';
  font-weight: 400;
  font-display: block;
  font-style: 'normal';
  src: url('./poly-sans/PolySans.ttf') format('truetype');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 500;
  font-display: block;
  font-style: 'normal';
  src: url('./poly-sans/PolySans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 600;
  font-display: block;
  font-style: 'normal';
  src: url('./poly-sans/PolySans-Bold.ttf') format('truetype');
}

/* New York */

@font-face {
  font-family: 'New York';
  font-weight: 400;
  font-display: block;
  font-style: 'normal';
  src: url('./new-york/NewYork.ttf') format('truetype');
}
